import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {Container, Row, Col} from "react-bootstrap";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../../components/PageHeaderBlock";
import BlogCard from "../../components/blog/BlogCard";
import {getImage} from "gatsby-plugin-image";
import BlogWidget from "../../components/widgets/BlogWidget";
import BlogWidget2 from "../../components/widgets/BlogWidget2";
import Breadcrumbs from "../../components/Breadcrumbs";

export default function SaytPodBiznes(query) {
    const { t } = useTranslation();
    const allBlogDataJson = query.data.allPostsDataJson;

    return (
        <Layout>
            <PageHeaderBlock h1="seoBusiness" />
            <Seo title={t('seoBusinessTitle')} description={t('seoBusinessDescription')} />
            <Container fluid className="px-flex">
                <Breadcrumbs firstChild="blogPageWord" firstChildUrl={'/blog'} secondChild="seoBusiness" />
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col xxl={2} className="mb-2 mb-xl-3 mb-xxl-0 pb-1 pb-xl-0"><BlogWidget /></Col>
                    <Col md={8}>
                        <Row>
                            {allBlogDataJson.nodes.map((item, index) => {
                                const blogImage = getImage(item.src);

                                return (
                                    <BlogCard item={item} blogImage={blogImage} index={index} key={`blog-item-${index}`} />
                                )
                            })}
                        </Row>
                    </Col>
                    <Col md={4} xxl={2}><BlogWidget2 /></Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPostsDataJson(filter: {category: {eq: "seoBusiness"}}) {
        nodes {
          category
          h1
          slug
          title
          src {
            childImageSharp {
              gatsbyImageData(formats: WEBP, placeholder: BLURRED)
            }
          }
        }
      }
  }
`;
